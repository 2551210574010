<template>
  <div class="shell-box">
    <el-breadcrumb separator="/"
      class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/routerClaimManagement/claimManagement' }">理赔列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加报案</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm ref="policyForm"
        :detailData="policyDetail"
        :policySubjectList="policySubjectList"
        :policyInsuredList="policyInsuredList"
        :policyDisabled="$route.query.policyId ? true : false"
        :permissions="policyDetailPermissions" />
    </div>
    <div class="operating-box">
      <el-button size="mini"
        @click="handleBack">返回</el-button>
      <el-button size="mini"
        type="primary"
        @click="handleReset"
        plain>重置</el-button>
      <el-button size="mini"
        type="primary"
        v-if="$route.query.claimId"
        @click="handleSaveDraft(2)">保存</el-button>
      <el-button size="mini"
        type="primary"
        v-else
        @click="handleSaveDraft(1)">存入草稿箱</el-button>
      <el-button size="mini"
        type="warning"
        plain
        @click="handlePolicyUpdate(1)">提交并返回列表</el-button>
      <el-button size="mini"
        type="primary"
        @click="handlePolicyUpdate(2)">提交并进入验标</el-button>
    </div>
  </div>
</template>

<script>
import policyForm from "@/views/policyManagement/components/applyClaimForm.vue";
import { getPolicyDetail } from "@/api/policy";
import { insClaimsCaseAdd, insClaimsCaseDraftAdd, insClaimsCaseDraftDetail, insClaimsCaseDraftUpdate, insClaimsCaseDraftSubmit } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyForm },
  data() {
    return {
      policyDetail: null,
      originalPolicyDetail: {},
      policySubjectList: [],
      policyInsuredList: [],

      policyDetailPermissions: {
        // 车架号
        frameNo: dataPermissions('M21-Add', 'frameNo'),
        // 案件跟进人
        caseFollower: dataPermissions('M21-Add', 'caseFollower'),
        // 现场联系人手机号
        siteContactPhone: dataPermissions('M21-Add', 'siteContactPhone'),
        // 出险类型适用条款
        dangerTypeClause: dataPermissions('M21-Add', 'dangerTypeClause'),
        // 业务员
        policySalesman: dataPermissions('M21-Add', 'policySalesman'),
        // 报案人姓名
        reportCasePersonName: dataPermissions('M21-Add', 'reportCasePersonName'),
        // 预估损失金额
        estimateLoss: dataPermissions('M21-Add', 'estimateLoss'),
        // 保司报案号
        realCaseNo: dataPermissions('M21-Add', 'realCaseNo'),
        // 保单号
        policyNo: dataPermissions('M21-Add', 'policyNo'),
        // 报案人联系电话
        reportCasePhone: dataPermissions('M21-Add', 'reportCasePhone'),
        // 设备型号
        equipmentType: dataPermissions('M21-Add', 'equipmentType'),
        // 现场联系人姓名
        siteContactName: dataPermissions('M21-Add', 'siteContactName'),
        // 保险公司
        policyCompany: dataPermissions('M21-Add', 'insuranceCompany'),
        // 第二被保险人
        policySecondInsured: dataPermissions('M21-Add', 'policySecondInsured'),
        // 出厂日期
        productionDate: dataPermissions('M21-Add', 'productionDate'),
        // 投保省份
        policyProvince: dataPermissions('M21-Add', 'policyProvince'),
        // 第一被保险人
        policyFirstInsured: dataPermissions('M21-Add', 'policyFirstInsured'),
        // 报案时间
        reportCaseTime: dataPermissions('M21-Add', 'reportCaseTime'),
        // 设备品牌
        brand: dataPermissions('M21-Add', 'brand'),
        // 设备类型
        deviceType: dataPermissions('M21-Add', 'deviceType'),
        // 事故原因
        accidentCause: dataPermissions('M21-Add', 'accidentCause'),
        // 案件责任比例
        dutyRate: dataPermissions('M21-Add', 'dutyRate'),
        // 设备受损部位
        damagedParts: dataPermissions('M21-Add', 'damagedParts'),
        // 案件编号
        caseNo: dataPermissions('M21-Add', 'caseNo'),
        // 小时数
        workTime: dataPermissions('M21-Add', 'workTime'),
        // 投保方式
        policyApplicantMode: dataPermissions('M21-Add', 'policyApplicantMode'),
        // 车架号后6位
        vinLast: dataPermissions('M21-Add', 'vinLast'),
        // 出险时间
        dangerDate: dataPermissions('M21-Add', 'dangerDate'),
        // 出险信息备注
        dangerRemark: dataPermissions('M21-Add', 'dangerRemark'),
        // 险种名称
        policyInsuranceType: dataPermissions('M21-Add', 'policyInsuranceType'),
        // 整备质量
        curbWeight: dataPermissions('M21-Add', 'curbWeight'),
        // 投保门店
        policyApplicantStore: dataPermissions('M21-Add', 'policyApplicantStore'),
        // 理算专员
        adjustmentAssistant: dataPermissions('M21-Add', 'adjustmentAssistant'),
        // 区域经理
        regionalManager: dataPermissions('M21-Add', 'regionalManager'),
        // 出险类型
        dangerTypeId: dataPermissions('M21-Add', 'dangerTypeId'),
        // 出险详细地址
        dangerDetailAddress: dataPermissions('M21-Add', 'dangerDetailAddress'),
        // 所属项目
        projectName: dataPermissions('M21-Add', 'projectName'),
      }
    };
  },
  created() {
    this.$route.query.policyId ? this.getDetail(this.$route.query.policyId) : this.$route.query.claimId ? this.getDraftDetail(this.$route.query.claimId) : '';
  },
  activated() {
    this.$route.query.policyId ? this.getDetail(this.$route.query.policyId) : this.$route.query.claimId ? this.getDraftDetail(this.$route.query.claimId) : '';
  },
  methods: {
    getDetail(id) {
      getPolicyDetail({
        id
      }).then(res => {
        this.policyDetail = {
          "policyApplicantMode": res.data.applicantModeName,
          "policyApplicantStore": res.data.applicantStoreName,
          "policyCompany": res.data.acceptCompanyList && res.data.acceptCompanyList.length ? res.data.acceptCompanyList[0].companyName : '',
          "policyEndDate": res.data.endDate,
          "policyFirstInsured": res.data.policyInsuredList && res.data.policyInsuredList.length ? res.data.policyInsuredList[0].playersName : '',
          "policyId": res.data.id,
          "policyInsuranceType": res.data.insuranceTypeName || '',
          "policyNo": res.data.policyNo,
          "policyProvince": res.data.province,
          "policySalesman": res.data.salesmanName,
          "policySecondInsured": res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList[1].playersName : '',
          "policyStartDate": res.data.startDate,
          "bindUserName": res.data.bindUserName,
          "bindUserId": res.data.bindUserId,
          "projectName": res.data.projectName,
        };
        this.policySubjectList = res.data.policySubjectList;
        res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList.splice(0, 1) : '';
        console.log(res.data.policyInsuredList);
        this.policyInsuredList = res.data.policyInsuredList || [];
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
      }).catch(err => {
        console.log(err);
      });
    },
    getDraftDetail(id) {
      insClaimsCaseDraftDetail({
        id
      }).then(res => {
        let policyDetail = res.data;
        res.data.policyId ? getPolicyDetail({
          id: res.data.policyId
        }).then(policyRes => {
          this.policySubjectList = policyRes.data.policySubjectList;
          policyDetail.bindUserName = policyRes.data.bindUserName;
          policyDetail.bindUserId = policyRes.data.bindUserId;
          this.policyDetail = policyDetail;
          policyRes.data.policyInsuredList && policyRes.data.policyInsuredList.length > 1 ? policyRes.data.policyInsuredList.splice(0, 1) : '';
          this.policyInsuredList = policyRes.data.policyInsuredList || [];
        }).catch(err => {
          console.log(err);
        }) : this.policyDetail = policyDetail;
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handlePolicyUpdate(type) {
      let data = await this.$refs.policyForm.handleSubmit();
      let claimType = {}
      if (this.$route.query.orderType) {
         claimType.orderType = this.$route.query.orderType;
         claimType.category = this.$route.query.category;
      }
      if (this.$route.query.claimId) {
        insClaimsCaseDraftSubmit({ ...data, gotoYB: type == 2 ? true : false }).then(res => {
          if (res) {
            if (type == 1) {
              this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            } else {
              this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            }
            this.$message.success('添加成功');
          }
        }).catch(err => {
          console.log(err);
        });
        return false;
      }
      insClaimsCaseAdd({ ...data, ...claimType, gotoYB: type == 2 ? true : false }).then(res => {
        if (res) {
          if (type == 1) {
            this.$router.push({ path: '/routerClaimManagement/claimManagement' });
          } else {
            this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
          }
          this.$message.success('添加成功');
        }
      }).catch(err => {
        console.log(err);
      });
    },
    async handleSaveDraft(type) {
      let data = await this.$refs.policyForm.handleSubmit('draft');
      let claimType = {}
      if (this.$route.query.orderType) {
         claimType.orderType = this.$route.query.orderType;
         claimType.category = this.$route.query.category;
      }
      if (type == 1) {
        insClaimsCaseDraftAdd({ ...data, ...claimType, gotoYB: false }).then(res => {
          if (res) {
            //  if (type == 1) {
            // this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            this.$router.go(-1);
            //  } else {
            // this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            //  }
            this.$message.success('添加成功');
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        insClaimsCaseDraftUpdate({ ...data, gotoYB: false }).then(res => {
          if (res) {
            //  if (type == 1) {
            // this.$router.push({ path: '/routerClaimManagement/claimManagement' });
            this.$router.go(-1);
            //  } else {
            // this.$router.push({ path: '/routerClaimManagement/claimDetails/' + res.data.id });
            //  }
            this.$message.success('编辑成功');
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    handleReset() {
      this.$refs.policyForm.handleReset();
      this.$forceUpdate();
    },
  }
}
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>